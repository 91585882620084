import Vue from 'vue'
import App from './App.vue'
import '@/filters'
import router from '@/router'
import store from '@/store'
import service from '@/service'
import config from '@/config'
import axios from 'axios'
import 'vue-multiselect/dist/vue-multiselect.min.css'; // vue-multiselect

// Vuesax Component Framework
import Vuesax from 'vuesax'
import 'material-icons/iconfont/material-icons.css' //Material Icons
import 'vuesax/dist/vuesax.css'; // Vuesax
Vue.use(Vuesax)

// VuiKit
import Vuikit from 'vuikit'
import VuikitIcons from '@vuikit/icons'
import '@vuikit/theme'

Vue.use(Vuikit)
Vue.use(VuikitIcons)

import VueMeta from 'vue-meta'
Vue.use(VueMeta)

// VeeValidate
import VeeValidateFR from 'vee-validate/dist/locale/fr';
import VeeValidateEN from 'vee-validate/dist/locale/en';
import VeeValidate, { Validator } from 'vee-validate';
window.Validator = Validator;
window.VeeValidateFR = VeeValidateFR;
window.VeeValidateEN = VeeValidateEN;
Vue.use(VeeValidate);

import VueSocialSharing from 'vue-social-sharing'

Vue.use(VueSocialSharing);

// Theme Configurations
import '../themeConfig.js'

// Globally Registered Components
import './globalComponents.js'

// Styles: SCSS
import './assets/scss/main.scss'

// Tailwind
import '@/assets/css/main.css'

// i18n
import i18n from './i18n/i18n'

// Vuejs - Vue wrapper for hammerjs
import { VueHammer } from 'vue2-hammer'
Vue.use(VueHammer)

// PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'

// Feather font icon
require('./assets/css/iconfont.css')

// Clipboard
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard);

import VueSocialChat from 'vue-social-chat'
Vue.use(VueSocialChat)

// Check if user is connect
store.dispatch('auth/verified')

Vue.prototype.$config = config
Vue.prototype.$service = service(axios, config)

Vue.config.productionTip = false

new Vue({
    router,
    store,
    i18n,
    render: h => h(App),
}).$mount('#app')
