export default {
  ticket: {
    add: {
      success: {
        color: "success",
        text: "Ticket ajouté avec succes",
        title: "Mis à jour"
      },
      error: {
        color: "danger",
        text: " Erreur lors de l'ajout du ticket'",
        title: "Mis à jour"
      }
    },
    close: {
      success: {
        color: "success",
        text: "Ticket fermé avec succes",
        title: "Mis à jour"
      },
      error: {
        color: "danger",
        text: " Erreur lors de la fermeture du ticket",
        title: "Mis à jour"
      }
    },
    file: {
      success: {
        color: "success",
        text: "Fichier ajouté avec succès",
        title: "Ajout"
      },
      error: {
        color: "danger",
        text: "Erreur lors de l'ajout du Fichier",
        title: "Ajout"
      }
    },
    comment: {
      success: {
        color: "success",
        text: "Commentaire envoyé avec succès",
        title: "Ajout"
      },
      error: {
        color: "danger",
        text: "Erreur lors de l'envoie du commentaire",
        title: "Ajout"
      }
    },
    commentUpdate: {
      success: {
        color: "success",
        text: "Commentaire modifié avec succès",
        title: "Mis à jour"
      },
      error: {
        color: "danger",
        text: "Erreur lors de la modification du commentaire",
        title: "Mis à jour"
      }
    },
    commentDelete: {
      success: {
        color: "success",
        text: "Commentaire supprimé avec succès",
        title: "Ajout"
      },
      error: {
        color: "danger",
        text: "Erreur lors de la suppression du commentaire",
        title: "Ajout"
      }
    },
  },
  slips: {
    add: {
      success: {
        color: "success",
        text: "Bordereau ajouté avec succès",
        title: "Ajout"
      },
      error: {
        color: "danger",
        text: "Erreur lors de l'ajout du bordereau",
        title: "Ajout"
      }
    },
    update: {
      success: {
        color: "success",
        text: "Bordereau modifié avec succès",
        title: "Mis à jour"
      },
      error: {
        color: "danger",
        text: "Erreur lors de la modification du bordereau",
        title: "Mis à jour"
      }
    },
    delete: {
      success: {
        color: "success",
        text: "Bordereau  supprimé avec succès",
        title: "Suppression"
      },
      error: {
        color: "danger",
        text: "Erreur lors de la suppression du bordereau",
        title: "Suppression"
      }
    },
    multipleDeletion: {
      success: {
        color: "success",
        text: "Bordereaux  supprimés avec succès",
        title: "Suppression"
      },
      error: {
        color: "danger",
        text: "Erreur lors de la suppression des bordereaux",
        title: "Suppression"
      }
    },
    file: {
      success: {
        color: "success",
        text: "Fichier ajouté avec succès",
        title: "Ajout"
      },
      error: {
        color: "danger",
        text: "Erreur lors de l'ajout du Fichier",
        title: "Ajout"
      }
    }
  },
  users: {
    mdp: {
      success: {
        color: "success",
        text: "Le mot de passe a été changé avec succès",
        title: "Changement du mot de passe"
      },
      error: {
        color: "danger",
        text: "Ancien mot de passe incorrect",
        title: "Changement du mot de passe"
      }
    },
    updateUser: {
      success: {
        color: "success",
        text: "Les informations ont été mises à jour avec succès",
        title: "Mise à jour profil"
      },
      error: {
        color: "danger",
        text: "Une erreur s'est produite",
        title: "Mise à jour profil"
      }
    },
    updateAvatar: {
      success: {
        color: "success",
        text: "Image a été téléchargée avec succès",
        title: "Mise à jour avatar"
      },
      error: {
        color: "danger",
        text: "Une erreur s'est produite",
        title: "Mise à jour profil"
      }
    },
    notFound: {
      error: {
        color: "danger",
        text: "Le nom d'utilisateur n'existe pas",
        title: "Utilisateur"
      },
    },
    nameExist: {
      error: {
        color: "danger",
        text: "Nom d'utilisateur existe déjà",
        title: "Utilisateur"
      },
    },
    mailError: {
      error: {
        color: "danger",
        text: "Impossible d'envoyer le mail à l'adresse",
        title: "Mail"
      },
    },
  }

};
